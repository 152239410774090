import { SET_REFRESH_NAVBAR_LIST } from '../actions/types';

const defaultState = {
  refreshNav: false,
};

export default function refreshNavbarList(state = defaultState, { type, payload }) {
  switch (type) {
    case SET_REFRESH_NAVBAR_LIST:
      return {
        refreshNav: payload,
      };
    default:
      return state;
  }
}
