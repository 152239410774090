import { SET_PICTURE_FULLSCREEN } from '../actions/types';

export default function pictureFullscreenReducer(state = null, { type, payload }) {
  switch (type) {
    case SET_PICTURE_FULLSCREEN:
      return payload;
    default:
      return state;
  }
}
