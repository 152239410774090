import { useCallback } from 'react';
import usePrevious from '../usePrevious';
import { MARKER_MIN_ZOOM, VIEW_PORT_DECIMAL } from '../../utils/constants';

const useViewportOverride = ({ viewport }) => {
  const {
    zoom, neLong, neLat, swLong, swLat,
  } = viewport || {};
  const prevNeLong = usePrevious(Number(neLong).toFixed(VIEW_PORT_DECIMAL));
  const prevNeLat = usePrevious(Number(neLat).toFixed(VIEW_PORT_DECIMAL));
  const prevSwLong = usePrevious(Number(swLong).toFixed(VIEW_PORT_DECIMAL));
  const prevSwLat = usePrevious(Number(swLat).toFixed(VIEW_PORT_DECIMAL));
  const prevZoom = usePrevious(Number(zoom).toFixed(VIEW_PORT_DECIMAL));

  const params = {
    byGeom: true,
    neLong,
    neLat,
    swLong,
    swLat,
  };

  const shouldInvalid = useCallback((minZoomLevel = MARKER_MIN_ZOOM, maxZoomLevel) => {
    if (!zoom || !neLong || !neLat || !swLong || !swLat) return true;

    if (Number(neLong).toFixed(VIEW_PORT_DECIMAL) === prevNeLong
      && Number(neLat).toFixed(VIEW_PORT_DECIMAL) === prevNeLat
      && Number(swLong).toFixed(VIEW_PORT_DECIMAL) === prevSwLong
      && Number(swLat).toFixed(VIEW_PORT_DECIMAL) === prevSwLat
      && Number(zoom).toFixed(VIEW_PORT_DECIMAL) > prevZoom) {
      return true;
    }

    if (zoom < minZoomLevel) return true;

    if (maxZoomLevel && zoom > maxZoomLevel) return true;

    return false;
  }, [
    neLong,
    neLat,
    swLong,
    swLat,
    zoom,
    prevNeLong,
    prevNeLat,
    prevSwLong,
    prevSwLat,
    prevZoom,
  ]);

  return {
    params,
    shouldInvalid,
  };
};

export default useViewportOverride;
