import { SET_IS_PROD_DISPLAYED } from '../actions/types';

export default function isProdDisplayedReducer(state = false, { type, payload }) {
  switch (type) {
    case SET_IS_PROD_DISPLAYED:
      return payload;
    default:
      return state;
  }
}
