import { SET_SETTINGS } from '../actions/types';

const initialState = {
  allocsDisplay: 'Chart',
  cropsDisplay: 'Chart',
  flowmeterUsageDisplay: 'Show',
  flowmeterUsageUnits: 'Same as meter',
  certUsageDisplay: 'Show',
  filesDisplay: 'Show',
  weatherLocation: 339331,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SET_SETTINGS:
      return payload || initialState;
    default:
      return state;
  }
}
