/* eslint-disable no-case-declarations */
import {
  WU_REPORT,
  CLEAR_WU_DATA,
} from '../actions/types';

const initialState = {
  type: null,
  year: null,
  data: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case WU_REPORT:
      const { type, year, data } = action.payload;

      return { type, year, data };
    case CLEAR_WU_DATA:
      return initialState;
    default:
      return state;
  }
}
