import { combineReducers } from 'redux';
import isProdDisplayed from './isProdDisplayed';
import newVersion from './newVersion';
import pictureUpload from './pictureUploadReducer';
import refreshNavbarList from './refreshNavbarListReducer';
import settings from './settingsReducer';
import user from './userReducer';
import wuReport from './wuReportReducer';
import dropDownOptions from './dropDownReducer';
import pictureFullscreenReducer from './pictureFullscreen';
import globalLoadingReducer from './globalLoading';

export default combineReducers({
  isProdDisplayed,
  newVersion,
  pictureUpload,
  refreshNavbarList,
  settings,
  user,
  wuReport,
  dropDownOptions,
  pictureFullscreen: pictureFullscreenReducer,
  globalLoading: globalLoadingReducer,
});
