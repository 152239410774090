import { SET_DROP_DOWN_OPTIONS } from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case SET_DROP_DOWN_OPTIONS:
      const { key, options } = action.payload;

      return {
        ...state,
        [key]: options,
      };;
    default:
      return state;
  }
}
