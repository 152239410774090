import { getRequest } from '../utils/fetch';
import { SET_DROP_DOWN_OPTIONS } from './types';

export const getDropDownOptions = (field) => async (dispatch) => {
  const data = await getRequest({ url: `/dropdownOptions/${field}` });

  if (data) {
    const options = [];
    data.forEach((el) => options.push(el));
    dispatch({ type: SET_DROP_DOWN_OPTIONS, payload: { key: field, options } });
  }
};