import { NEW_VERSION } from '../actions/types';

export default function newVersionReducer(state = false, { type }) {
  switch (type) {
    case NEW_VERSION:
      return true;
    default:
      return state;
  }
}
