import getUserNrd from './getUserNrd';
import toDataUrl from './toDataUrl';

export default async function getNrdLogo(nrd) {
    try {
        const logoNrd = nrd || getUserNrd();
        const { default: logoUrl } = await import(`../assets/img/nrdLogos/${logoNrd}NRD.jpg`)
        return await toDataUrl(logoUrl)
    } catch (err) {
        return null;
    }
}