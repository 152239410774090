/* eslint-disable operator-linebreak */

if (process.env.NODE_ENV !== 'production') {
  console.log('It is in developement');
}

export const apiRootURL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ROOT_URL
    : 'http://localhost:4000';

export const userPoolId =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_USER_POOL_ID
    : 'us-east-2_bDYJ5zlJE';

export const userPoolWebClientId =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
    : '4gdmaja9jmjlm27n4i56lb42us';

export const nrds =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_NRDS.split(',')
    : [
        'NP',
        'SP',
        'UNW',
        'PM',
        'MN',
        'UL',
        'UE',
        'LC',
        'TB',
        'LBB',
        'UR',
        'MR',
        'LE',
        'LR',
        'LN',
        'CP',
        'OWW',
        'EMP',
        'PWU',
        'FM',
        'BR',
        'Nemaha',
      ];
