import request from '../utils/fetch';
import { SET_SETTINGS } from './types';

export const getSettings = () => async (dispatch) => {
  const data = await request({ url: '/v2/user-settings', method: 'get' });
  if (data?.values) dispatch({ type: SET_SETTINGS, payload: data.values });
};

export const setSettings = (values) => (dispatch, getState) => {
  if (!getState()?.user?.isDemo) request({ method: 'put', url: '/v2/user-settings', data: values });
  dispatch({ type: SET_SETTINGS, payload: values });
};
