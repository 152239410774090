import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

export default function NewVersionNotification() {
  const show = useSelector((s) => s.newVersion);

  useEffect(() => {
    if (show) {
      toast.info('A new version is available - refresh to switch to it.');
    }
  }, [show, toast]);

  return null;
}
