import getUserNrd from '../utils/getUserNrd';

export const locations = {
  NP: {
    longitude: -103,
    latitude: 41.75,
    zoom: 8.5,
  },
  SP: {
    longitude: -103,
    latitude: 41.2,
    zoom: 8.5,
  },
  UNW: {
    longitude: -103,
    latitude: 42.5,
    zoom: 8.35,
  },
  PM: {
    longitude: -96.45,
    latitude: 41.75,
    zoom: 7.75,
  },
  MN: {
    longitude: -101,
    latitude: 42.75,
    zoom: 8.1,
  },
  UL: {
    longitude: -101,
    latitude: 42,
    zoom: 8.1,
  },
  UE: {
    longitude: -98.8,
    latitude: 42.3,
    zoom: 8.3,
  },
  LC: {
    longitude: -97.26,
    latitude: 42.62,
    zoom: 8.2,
  },
  TB: {
    longitude: -99.37,
    latitude: 40.44,
    zoom: 8.2,
  },
  LBB: {
    longitude: -96.743,
    latitude: 40.268,
    zoom: 8.0,
  },
  UR: {
    longitude: -101.6426,
    latitude: 40.522,
    zoom: 9.3,
  },
  LE: {
    longitude: -97.135,
    latitude: 42.003,
    zoom: 9.3,
  },
  MR: {
    longitude: -100.727,
    latitude: 40.481,
    zoom: 9.5,
  },
  LR: {
    longitude: -99.362,
    latitude: 40.0975,
    zoom: 9.5,
  },
  LN: {
    longitude: -98.8493,
    latitude: 42.9114,
    zoom: 9.5,
  },
  CP: {
    longitude: -98.342,
    latitude: 40.9264,
    zoom: 9.5,
  },
  OWW: {
    longitude: -104.0577,
    latitude: 40.2473,
    zoom: 11,
  },
  EMP: {
    longitude: -104.0577,
    latitude: 40.2473,
    zoom: 11,
  },
  PWU: {
    longitude: -103.2706,
    latitude: 40.5481,
    zoom: 11,
  },
  FM: {
    longitude: -103.8014,
    latitude: 40.2546,
    zoom: 14
  },
  BR: {
    longitude: -103.6310,
    latitude: 40.2555,
    zoom: 14
  },
  Nemaha: {
    longitude: -96.1888,
    latitude: 40.3726,
    zoom: 11
  }
};

export default function getNrdLocation() {
  const nrd = getUserNrd();
  if (nrd) return locations[nrd];
  return Object.values(locations)[0];
}
