export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_SETTINGS = 'SET_SETTINGS';
export const SET_REFRESH_NAVBAR_LIST = 'SET_REFRESH_NAVBAR_LIST';
export const NEW_VERSION = 'NEW_VERSION';
export const SET_PICTURE_TO_UPLOAD = 'SET_PICTURE_TO_UPLOAD';
export const SET_PICTURE_TO_DELETE = 'SET_PICTURE_TO_DELETE';
export const CLEAR_PICTURE = 'CLEAR_PICTURE';
export const SET_IS_PROD_DISPLAYED = 'SET_IS_PROD_DISPLAYED';

// imports
export const IMPORT_STARTED = 'IMPORT_STARTED';
export const PACKET_STARTED = 'PACKET_STARTED';
export const PACKET_FINISHED = 'PACKET_FINISHED';
export const PACKET_FAILED = 'PACKET_FAILED';

// WU Reports
export const WU_REPORT = 'WU_REPORT';
export const CLEAR_WU_DATA = 'CLEAR_WU_DATA';

// DropDown Options
export const SET_DROP_DOWN_OPTIONS = 'SET_DROP_DOWN_OPTIONS';

// Picture Fullscreen
export const SET_PICTURE_FULLSCREEN = 'SET_PICTURE_FULLSCREEN';

// Global Loading
export const SET_LOADING_FULLSCREEN = 'SET_LOADING_FULLSCREEN';
