import {
  SET_REFRESH_NAVBAR_LIST, NEW_VERSION, SET_PICTURE_FULLSCREEN, SET_LOADING_FULLSCREEN,
} from './types';

export * from './settingsActions';
export * from './userActions';
export * from './wuReportActions';
export * from './dropDownActions';

export const setRefreshNavbarList = (refresh = false) => ({
  type: SET_REFRESH_NAVBAR_LIST,
  payload: refresh,
});

export const newVersion = () => ({ type: NEW_VERSION });

export const setPictureFullscreen = (image) => ({ type: SET_PICTURE_FULLSCREEN, payload: image });

export const showGlobalLoading = (show) => ({ type: SET_LOADING_FULLSCREEN, payload: show });
