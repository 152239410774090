import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as StoreProvider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import GoogleFontLoader from 'react-google-font-loader';
// TODO: With adding Chakra, we need to remove Font Awesome, Bootstrap, MDB
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import 'bootstrap-css-only/css/bootstrap.min.css';
// import 'mdbreact/dist/css/mdb.css'; Override for custom scss
// import './assets/scss/mdb.scss'; // use _custom-variables.scss to override rules.
import './assets/scss/index.scss';
import './assets/bundle.css';
import "react-datepicker/dist/react-datepicker.css";

import App from './components/App';
import NewVersionNotification from './components/NewVersionNotification';
import registerServiceWorker from './registerServiceWorker';
import store from './store';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://9856d06d4915419ea1e6e2bfb768c2e6@o429864.ingest.sentry.io/5378802',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    beforeSend(event) {
      if (event.type === 'envelope') {
        return null;
      }
      return event;
    },
  });
}

// error boundary
class ErrorBoundary extends React.Component {
  componentDidCatch(error, info) {
    if (process.env.NODE_ENV === 'development') throw error;
    else console.warn(error, info);
  }

  render() {
    return (
      <StoreProvider store={store}>
        <GoogleFontLoader
          fonts={[
            {
              font: 'Poppins',
              weights: [300, 400, 600, 700],
            },
          ]}
        />
        <App />
        <NewVersionNotification />
      </StoreProvider>
    );
  }
}

createRoot(document.getElementById('root')).render(<ErrorBoundary />);
registerServiceWorker();
