import { SET_LOADING_FULLSCREEN } from '../actions/types';

export default function globalLoadingReducer(state = false, { type, payload }) {
  switch (type) {
    case SET_LOADING_FULLSCREEN:
      return payload;
    default:
      return state;
  }
}
