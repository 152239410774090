import { SET_PICTURE_TO_UPLOAD, SET_PICTURE_TO_DELETE, CLEAR_PICTURE } from '../actions/types';

export default function pictureUploadReducer(state = {}, { type, payload }) {
  switch (type) {
    case SET_PICTURE_TO_UPLOAD:
      return { ...state, [payload.docId]: payload };
    case SET_PICTURE_TO_DELETE:
      return { ...state, [payload.deletePicture]: payload };
    case CLEAR_PICTURE:
      return {};
    default:
      return state;
  }
}
