import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import request from '../../utils/fetch';
import '../../assets/scss/MapSearch.scss';
import Button from '../common/2.0/Button';
import Input from '../common/2.0/Input';
import { toast } from 'react-toastify';

let sections = [];
request({ url: 'https://opendata.arcgis.com/datasets/e95148e3dbaa4179b1fd3d3a2858cb3a_2.geojson' })
  .then((data) => { sections = data.features; });

export default function MapSearch({ mapRef }) {
  const [value, setValue] = useState('');
  const [valid, setValid] = useState(false);
  const [redInput, setRedInput] = useState(false);
  const [notFound, setNotFound] = useState(false);

  function handleChange(value) {
    const newVal = value.toUpperCase();
    setValue(newVal);
    setRedInput(false);
    const parts = newVal.split('-').filter((x) => x);
    let isValid = parts.length === 3;
    if (isValid) {
      if (isNaN(parts[0])) isValid = false;
      const part1NoZeros = parts[1].split('0').join('');
      const part2NoZeros = parts[2].split('0').join('');
      if (
        isNaN(parts[1])
        && part1NoZeros !== `${parseInt(part1NoZeros, 10)}N`
        && part1NoZeros !== `${parseInt(part1NoZeros, 10)}S`
      ) {
        isValid = false;
      }
      if (
        isNaN(parts[2])
        && part2NoZeros !== `${parseInt(part2NoZeros, 10)}W`
        && part2NoZeros !== `${parseInt(part2NoZeros, 10)}E`
      ) {
        isValid = false;
      }
    }
    setValid(isValid);
  }

  async function handleClick() {
    setRedInput(false);

    const parts = value.split('-');

    const section = parseInt(parts[0], 10);
    const township = parseInt(parts[1], 10);
    const townshipDir = isNaN(parts[1]) ? parts[1].slice(-1).toUpperCase() : 'N';
    const range = parseInt(parts[2], 10);
    const rangeDir = isNaN(parts[2]) ? parts[2].slice(-1).toUpperCase() : 'W';

    try {
      const feature = await request({
        method: 'post',
        url: '/v2/layers/sectionCentroid',
        data: {
          Range: range,
          RangeDir: rangeDir,
          Section: section,
          Township: township,
          TownshipDir: townshipDir,
        },
      });

      if (feature?.coordinates) {
        let { coordinates } = feature;
        if (feature.type === 'MultiPolygon') [coordinates] = coordinates;
        // coordinates = coordinates.slice(1);
        const [avgLong, avgLat] = coordinates;
        mapRef.current.goTo({
          center: [avgLong, avgLat], // Target coordinate (longitude, latitude)
          zoom: 15 // Optional: set zoom level
        });
      } else {
        setNotFound(true);
        setTimeout(() => setNotFound(false), 3000);
      }
    } catch (e) {
      toast.error(e.response?.data?.message || e.message);
    }
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      if (valid) handleClick();
      else setRedInput(true);
    }
  }

  return (
    <div className="MapSearch">
      <div className="flex items-center relative w-full">
        <Button
          type="transparent"
          className="px-1 py-2 absolute right-0 top-0 h-full mr-1"
          onClick={handleClick}
          disabled={!valid}
        >
          <FontAwesomeIcon icon={faSearch} />
        </Button>
        <Input
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder="Search PLSS"
          hint="(Eg. 6-21N-56W)"
          containerClass="my-0"
          className={redInput ? 'error' : ''}
        />
        {notFound && <p className="red-text">Section not found</p>}
      </div>
    </div>
  );
}
