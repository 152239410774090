import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from '../../assets/fonts/vfs_fonts';

pdfMake.vfs = pdfFonts;

pdfMake.fonts = {
  Arial: {
    normal: 'ArialCE.ttf',
    bold: 'ArialCEBold.ttf',
    italics: 'ArialCEItalic.ttf',
    bolditalics: 'ArialCEBoldItalic.ttf',
  },
};

export default function createPdf(
  definition,
  returnData,
  extraReturnData,
  returnAndOpen,
) {
  const pdfReport = pdfMake.createPdf(definition);
  if (returnData) {
    return {
      report: pdfReport,
      extraReturnData,
    };
  }
  if (returnAndOpen) {
    pdfReport.open();
    return pdfReport;
  }
  pdfReport.open();
}
